@import "../../../styles/Utils/Breakpoints";


.waste-wrapper {
    position: absolute;
    left: 20vw;
    bottom: 25vh;
    width: 20vw;
    height: 80%;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: 1;

    border-bottom-left-radius: 20vh;
    overflow: hidden;

    @include media('<=tablet') {
        left: 40vw;
        bottom: 2vh;
        width: 80vw;
        height: 100%;
    }



    svg {
        max-width: 100%;
        height: auto;
    }
}