.home-container {
  display: grid;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  // background-color: $background;

  grid-template-columns: 12% auto auto 12%;
  grid-template-rows: 80px auto 100px;


  @include media('<=tablet') {
    grid-template-columns: 8% auto auto 8%;
    grid-template-rows: 60px 40% auto 15%;
  }


  .hero {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    // border: thin red groove;
    // margin: 0 15% 0 15%;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;



    @include media('<=tablet') {
      padding-top: 15vh;
    }

    .slogan {
      font-size: 5em;
      font-weight: bold;
      line-height: 1.1em;

      @include media('<=tablet') {
        font-size: 2.5em;
        border-radius: 10px;
      }

    }

    .select {
      text-decoration-line: underline;
      text-decoration-style: initial;
      text-decoration-color: $highlight;
      cursor: pointer;

      transition: all 0.4s ease-in-out;

      &--active {
        transition: all 0.4s ease-in-out;
        text-decoration-line: underline;
        text-decoration-style: initial;
        text-decoration-color: $highlight;
        color: $highlight;
        cursor: pointer;
      }
    }

  }


  .footer {
    // grid-row: 3/4;
    // grid-column: 2/4;
    // Position ABSOLUT to fix it to the bottom -> IPHONE SHIT
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $backgroundFade;

    .spacer {
      position: absolute;
      left: -5px;
      bottom: -8vh;
      width: 104vw;
      z-index: -4;
      transform: scaleY(.5) rotate(0deg);


      @include media('<=tablet') {
        transform: scaleY(1);
        bottom: 8vh;
      }
    }

    .highlight {
      bottom: -1vh;
      -webkit-filter: drop-shadow(0px -20px 5px rgba(49, 54, 56, 0.295));
      filter: drop-shadow(0px -2px 5px rgba(49, 54, 56, 0.39));

      @include media('<=tablet') {
        bottom: 10vh;
      }
    }

    @include media('<=tablet') {
      width: 100%;
      height: 12vh;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease-in-out;


      .links-wrapper {
        width: 2em;
        height: 1.5em;
        margin: 0 .5em 0 .5em;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        @include media('<=tablet') {
          width: 1em;
          height: 1.5em;
        }

        &:hover {
          filter: drop-shadow(0px 0px 2px $highlight);
        }

        svg {
          width: auto;
          max-height: 100%;
        }
      }
    }

    .cred {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 15px;

      @include media('<=tablet') {
        margin-top: 2px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: .1em;
      }

      p {
        font-size: 0.8em;

        @include media('<=tablet') {
          margin-top: 1rem;
          font-size: 0.6em;
        }
      }
    }

    .stats {
      position: absolute;
      right: 12vw;
      bottom: 4vh;
      width: 15vw;
      height: 15vh;

      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: .8em;
      }

      @include media('<=tablet') {
        left: 8vw;
        bottom: 4vh;
        width: 25vw;
        height: 10vh;
        align-items: flex-start;

        p {
          font-size: .5em;
        }

      }

    }



  }


  .modelwrapper {
    background-color: $background;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      bottom: 0;

      @include media('<=tablet') {
        width: 150%;
        margin-right: 0%;
        transform: scaleX(1);
      }
    }
  }

}