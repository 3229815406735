@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
// IMPORT COSTUM FONTS (MUST BE REFD IN BODY TAG)

@import "./Utils/Breakpoints";
@import "./Utils/CloseSign";
@import "./Utils/Variables";

@import "./Hamburger";
@import "./Header";
@import "./Home";
@import "./StorageApp";
@import "./Algae";
@import "./Contact";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: $text;
  // border: 1px solid red;
}






.tiny {
  font-size: 1em;

  @include media('<=tablet') {
    font-size: .7em;
  }
}

.bold {
  font-weight: bold;
}

.padding {
  padding-bottom: 1em;
  padding-top: 1em;

  @include media('<=tablet') {
    padding-bottom: .8em;
    padding-top: .8em;
  }
}

.spread {
  letter-spacing: .2em;
}

.hit {
  text-decoration-line: underline;
  text-decoration-style: initial;
  text-decoration-color: $highlight;
  font-weight: 700;
}

.bulletpoint {
  &:before {
    content: "• "
  }
}