.stoapp-container {
  height: auto;
  width: 100%;
  overflow: hidden;
  background-color: $background;

  // HEADER

  .stoAppHeader {
    position: relative;
    top: 2%;
    width: 100%;
    height: 80vh;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    z-index: 2;


    @include media('<=tablet') {
      top: 0%;
    }

    .introText {
      position: absolute;
      top: 15vh;
      left: 0;
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 0;

      @include media('<=tablet') {
        top: 25vh;
        left: 0;
        z-index: -100;
      }


      .slogan {
        font-size: 10em;

        @include media('<=tablet') {
          font-size: 3.5em;
        }
      }


      p {
        font-size: 1em;
        margin-top: 1rem;

        @include media('<=tablet') {
          margin-top: .8rem;
          font-size: 0.6em;
        }
      }
    }

    .img-wrapper {
      position: absolute;

      width: 100%;
      height: auto;
      z-index: -1;
      overflow: hidden;
      left: 0%;
      top: 15%;


      @include media('<=tablet') {
        width: 180%;
        left: -40%;
        top: 60%;
      }

      img {
        max-height: auto;
        max-width: 100%;

        @include media('<=tablet') {
          max-height: 100%;
          max-width: auto;
          transform: scale(1);
        }
      }
    }


  }







  // PROBLEM


  .stoAppProblem {
    position: relative;
    height: 100vh;
    width: 100vw;
    padding-bottom: 8vh;
    padding-top: 8vh + 8vh;
    z-index: 1;

    @include media('<=tablet') {
      padding-bottom: 5vh;
    }


    .stoAppProblemSpacer {
      position: absolute;
      top: -20vh;
      left: 0;
      width: 100vw;
      height: 40vh;
      background-color: $backgroundFade2;
      border-radius: 100vw 0 0 0;


      @include media('<=tablet') {
        top: -10vh;
        height: 30vh;
        border-radius: 0 0 0 0;
      }
    }


    .stoAppProblemHeader {
      position: absolute;
      top: 15vh;
      width: 100vw;
      height: auto;

      padding-left: 4em;
      padding-right: 4em;

      font-size: 2.5em;
      font-weight: bold;

      z-index: 2;



      @include media('<=tablet') {
        font-size: 1.4em;
        padding-left: 1em;
        padding-right: 1em;
      }
    }


    .backdrop {
      background: linear-gradient(-17deg, $backgroundFade3, $backgroundFade2 50.71%);
      background-color: $backgroundFade2;


      width: 100%;
      height: auto;

      padding-left: 4em;
      padding-right: 4em;
      padding-top: 10vh;

      border-radius: 0 0 2em 20em;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;




      @include media('<=tablet') {
        height: 100%;
        border-radius: 0 0 2em 50vw;

        align-items: flex-start;
        flex-direction: row;

        padding-left: 1em;
        padding-right: 1em;
        padding-top: 15vh;
      }


      // FOR IPHONE

      @include media('<380px') {
        height: 120%;
        border-radius: 0 0 2em 50vw;

        align-items: flex-start;
        flex-direction: row;

        padding-left: 1em;
        padding-right: 1em;
        padding-top: 15vh;
      }

      .stoAppProblemPara {
        height: auto;
        width: 50vw;
        padding: .5em;
        z-index: 2;

        @include media('<=tablet') {
          width: 100vw;
        }
      }



      .ProblemWrapper {
        width: 50vw;

        display: flex;
        flex-direction: column;

        padding: .5em;
        z-index: 2;

        @include media('<=tablet') {
          width: 100%;
        }
      }

    }

    //IMAGES FOR PROBLEM 


    .stoAppImage {
      // background-color: green;
      position: absolute;
      bottom: -12vh;
      right: -1vw;
      width: 40%;
      opacity: 1;
      overflow: hidden;

      z-index: 10;

      @include media('<=tablet') {
        bottom: -6vh;
        right: -2vh;
        width: 80vw;
      }

      @include media('<380px') {
        bottom: -10vh;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }



    .stoAppWaste {
      // background-color: green;
      position: absolute;
      bottom: 8vh;
      left: 0vw;
      width: auto;
      height: 70vh;
      opacity: 1;
      overflow: hidden;
      z-index: 1;
      opacity: .4;

      @include media('<=tablet') {
        left: 0vw;
        bottom: 5vh;
        width: auto;
        height: 50vh;
      }

      img {
        width: auto;
        max-height: 100%;
      }
    }


    .stoAppProblemIcon {
      position: absolute;
      bottom: 10em;
      left: 60vw;
      width: 20vw;
      height: auto;
      z-index: 10;


      @include media('<=tablet') {
        bottom: 5em;
        left: 45vw;
        width: 20vw;
      }


      svg {
        -webkit-filter: drop-shadow(2px 2px 2px rgba(69, 75, 82, 0.7));
        filter: drop-shadow(2px 2px 2px rgba(69, 75, 82, 0.7));
      }
    }


    .rise {
      background-color: rgb(90, 106, 122);
      position: absolute;
      bottom: -3vh;
      right: 0vh;
      height: auto;
      width: 40vw;
      opacity: 1;
      overflow: hidden;

      border-top-left-radius: 30px;
      border-bottom-left-radius: 80px;

      z-index: 2;

      padding-left: 5em;
      padding-top: 2em;
      padding-bottom: 2em;

      @include media('<=tablet') {
        bottom: -9vh;
        width: 90vw;
        height: auto;


        border-top-left-radius: 50px;
        border-bottom-left-radius: 20px;

        padding-left: 3em;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
      }

      @include media('<380px') {
        bottom: -20vh;
      }

      img {
        max-width: 100%;
        height: auto;
      }


    }


  }



  // FIRST SECTION 


  .first_section {
    position: relative;
    // background-color: rgba(195, 0, 255, 0.267);
    height: 300vh;
    width: 100vw;



    @include media('<=tablet') {
      height: 250vh;
    }

    @include media('<380px') {
      margin-top: -10vh;
      height: 300vh;
      bottom: -20vh;
    }


    
    .architectureImg {
      position: absolute;
      top: 5vh;
      right: 15vw;
      width: auto;
      height: 50vh;
      display: flex;
      justify-content: flex-end;
      opacity: .7;

      img {
        max-height: 100%;
        width: auto;
      }

      @include media('<=tablet') {
        justify-content: center;
        top: 18vh;
        right: 0vw;
        height: 30vh;
      }


    }

    .descriptionWrapper {
      position: absolute;
      left: 20vw;
      top: 0;
      width: 65%;
      height: 20vh;
      padding: 2em;

      opacity: 0;
      z-index: 2;

      @include media('<=tablet') {
        left: 5vw;
        width: 70%;
        top: 10vh;
        height: 60vh;
      }

    }


    .demoWrapper {
      position: absolute;
      right: 0;
      top: 60vh;
      width: 50%;
      height: auto;
      padding: 5em 2em 4em 4em;
      background: linear-gradient(90deg, $backgroundFade3, $backgroundFade2 50.71%);
      background-color: $backgroundFade3;

      border-radius: 80px 20px 180px 30px;

      @include media('<=tablet') {
        width: 60%;
        top: 60vh;
        padding: 2em .5em 1em .8em;
        border-radius: 10px 80px 80px 10px;
      }


      .demoWrapperHeadline {
        position: absolute;
        top: -3em;
        left: 7em;
        width: 100%;


        @include media('<=tablet') {
          width: 100%;
          top: -3em;
          left: 0em;
        }
      }


      .demoDescrpWrapper {
        margin: 1.5em 0 1.5em 0;
      }

    }

    .upcomingWrapper {
      margin: 1em 0em 1em 0em;
      padding: 2em 2em 2em;
      width: 120%;
      height: auto;
      background-color: $backgroundFade;
      border-radius: 20px 0px 0px 20px;
      box-shadow: 0 2px 4px 0 rgba(55, 65, 78, 0.2), 0 3px 10px 0 rgba(58, 63, 73, 0.19);

      @include media('<=tablet') {
        padding: 1em 1em 1em;
        border-radius: 10px 0px 0px 10px;
      }

      svg {
        position: absolute;
        top: -1em;
        left: 15em;
        width: 60px;
        height: 60px;

        @include media('<=tablet') {
          top: -1em;
          left: 35vw;
          width: 50px;
          height: 50px;
        }

      }
    }

    .phonedummy {
      position: absolute;
      left: -5vw;
      top: 35vh;
      height: auto;
      width: 70%;

      opacity: 1;
      padding: .5em;
      overflow: hidden;

      svg {
        z-index: 1;
      }

      @include media('<=tablet') {
        left: -45%;
        top: 50vh;
        width: 135%;
      }

      .phoneshowchase {
        position: absolute;
        top: 0;
        z-index: -2;
        // background-color: green;
        width: 24%;
        left: 36%;
        height: 69%;
        top: 17%;
        display: flex;
        align-items: center;
        justify-content: center;
      
        img {
          max-width: 95%;
          height: auto;
        }
      }



    }


  }



  // GET APP


  .stoAppGetApp {
    z-index: 1;
    margin-top: 1em;
    position: relative;
    // background: linear-gradient(90deg, $backgroundFade2, $backgroundFade3 50.71%);
    // background-color: $backgroundFade3;
    height: 60vh;
    width: 100%;
    border-radius: 0em 20em 2em 0;
    padding: 2em 2em 2em 55%;
    // box-shadow: 0 2px 20px 0 rgba(55, 65, 78, 0.452);


    @include media('<=tablet') {
      border-radius: 0em 10em 2em 0;
      height: 40vh;
      padding: 2em 2em 2em 40%;
      width: 100%;
    }

    

    .androidWrapper {
      position: absolute;
      bottom: 50%;
      right: 3em;
      height: auto;
      width: 6em;
      opacity: .2;
      @include media('<=tablet') {
        bottom: 20%;
        width: 4em;
        right: 0em;
      }
    }


    .downloadWrapper {
      z-index: 1;
      position: absolute;
      bottom: 20vh;
      right: -2em;
      height: 10vh;
      width: 25vw;
      padding: .25em 1em;
      background-color: $menu;
      border-radius: 2em 0em 0em 10em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 4px 10px 0 rgba(55, 65, 78, 0.2), 0 3px 10px 0 rgba(58, 63, 73, 0.19);

      font-size: 14px;
      transition: all 0.4s ease-in-out;

      @include media('<=tablet') {
        bottom: 1em;
        right: -2em;
        width: 50vw;
        height: 8vh;
      }

      @include media('<380px') {
        bottom: -1em;
      }

      a,p {
        font-size: 3em;
        color: white;

        @include media('<=tablet') {
          font-size: 1.5em;
        }
      }

    }

    .downloadDevice {
      position: absolute;
      bottom: 10vh;
      right: -2em;
      height: 20vh;
      width: 30vw;
      padding: .25em 1em;
      background-color: $backgroundFade3;
      border-radius: 2em 0em 0em 10em;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 14px;
      transition: all 0.4s ease-in-out;

      @include media('<=tablet') {
        bottom: 1.5em;
        right: -2em;
        width: 50vw;
        height: 10vh;
      }

    }

  }


  // FOOTER


  .stoAppFooter {
    margin-top: 5vh;
    position: relative;
    bottom: 0;
    right: 0;
    background-color: $backgroundFade2;
    height: 40vh;
    width: 100vw;

    border-radius: 10em 0vw 0 0;

    @include media('<=tablet') {
      margin-top: 7em;
      height: 25vh;
    }

    .textWrapper {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;


      @include media('<=tablet') {
        padding-bottom: 2em;
        justify-content: flex-end;
        top: -100px;
        right: 0;
        width: 100vw;
        height: 100%;
      }

      Link {
        z-index: 5;
      }

    }

    .imgWrapper {
      position: absolute;
      
      // background-color: red;
      top: -100px;
      right: 0vw;
      width: 35vw;
      height: 100%;
      z-index: 1;
      // overflow: hidden;

      @include media('<=tablet') {
        top: -5em;
        right: -5em;
        width: 80vw;
        height: 100%;
      }

      img {
        transform: translate(4vw, 2vh) rotate(270deg);
        // max-width: 100%;
        // height: auto;
        width: auto;
        max-height: 150%;

        @include media('<=tablet') {
          max-height: 100%;
        }
      }
    }


  }


}