$background: #f0f0f0;
$shadow: rgb(91, 107, 123);


$menu: #5b6b7b;

$primary: #adb4c0;
// $highlight: #D1AC00;
// $highlight: #fd4e4e;
$highlight: #db7070;

$text: #222222;
$backgroundFade: #687887;
$backgroundFade2:hsl(210, 13%, 70%);
$backgroundFade3: #b8bfc7;



// Colors to go
// rebeccapurple
// $Head: #D29260;
// $Head: #e7ad81
// $test: #663399 
// $blue: #649ad8 
$hose: #3c3f44;

:export {
    background: $background;
    menu: $menu;
    primary: $primary;
    highlight: $highlight;
    text: $text;
    backgroundFade: $backgroundFade;
    backgroundFade2: $backgroundFade;
    backgroundFade3: $backgroundFade;
}