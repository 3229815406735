.contactContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: $background;
  display: flex;

  align-items: center;

  @include media('<=tablet') {
    flex-direction: column;
  }


  .contactText {

    width: 50%;
    height: 80%;

    // border: thin green groove;

    opacity: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12%;

    transition: all 0.4s ease-in-out;


    @include media('<=tablet') {
      padding: 8%;
      width: 100%;
      height: 40%;
      margin-top: 20%;
    }

    @media (max-height: 600px) {
      height: 30%;
      opacity: 0.1;
    }


    .links {
      display: flex;
      width: 100%;
      height: 40px;
      margin: 1em 0 0 0;

      .links-wrapper {
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 50px;

        svg {
          width: 26%;
        }
      }

    }


  }

  .contactForm {

    z-index: 2;
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5% 0 0 0;

    transition: all 0.4s ease-in-out;

    @include media('<=tablet') {
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 40%;
    }

    @media (max-height: 600px) {
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 60%;
      margin-top: -10em;
    }

    form {

      width: 60%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;


      @include media('<=tablet') {
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        height: 120%;
      }
  
      @media (max-height: 600px) {
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        height: 120%;
        // margin-top: -10em;
      }


      .form-input {
        width: 100%;
        margin: .5em 0;
        padding: 0 0;
        background: none;
        border: none;
        border-bottom: 1px solid $text;
        color: $text;
        font-size: 14px;
        outline: none;
        transition: all 0.3s ease-in-out;
        background-color: $background;
        padding: 1em 1em 0em 1em;
        border-radius: .2em .2em 0 0;
        -webkit-filter: drop-shadow(.05em -.3em .05em rgba(79, 82, 97, 0.205));
        filter: drop-shadow(-.02em .2em 0.1em $backgroundFade2);

        &:focus {
          width: 105%;
          border-bottom: 2px solid $highlight;
          margin: 1em 0;
          -webkit-filter: drop-shadow(.05em -.1em .2em rgba(79, 82, 97, 0.205));
          filter: drop-shadow(-.05em .2em .4em $backgroundFade3);
        }

        &:hover {
          opacity: .7;
        }

        &-invalid {
          width: 100%;
          margin: 1em 0;
          padding: 0 0;
          background: none;
          border: 1px $highlight groove;
          color: $text;
          font-size: 14px;
          outline: none;
          transition: all 0.3s ease-in-out;
          background-color: $background;
          padding: 1em 1em 0em 1em;
          border-radius: .2em .2em 0 0;
          -webkit-filter: drop-shadow(0em 0em .2em $highlight);
          filter: drop-shadow(0em 0em .2em $highlight);
        }


      }


      .captcha-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          height: 30px;
        }

        .form-input {
          width: 50%;

          &-invalid {
            width: 50%;
          }
        }
      }


      .btnSubmit {
        opacity: 1;
        width: 25%;
        height: 3em;
        margin: 1em 0;
        padding: 0 0;
        background-color: $backgroundFade3;
        border: none;
        border-bottom: 1px solid $text;
        color: $text;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        padding: 1em 1em 0.5em 1em;
        border-radius: .2em .2em 0 0;
        -webkit-filter: drop-shadow(.05em -.3em .05em rgba(79, 82, 97, 0.205));
        filter: drop-shadow(-.02em .2em 0.1em $backgroundFade2);

        @include media('<=tablet') {
          width: 30%;
        }


        &inactive {
          opacity: .1;
          width: 25%;
          height: 3em;
          margin: 1em 0;
          padding: 0 0;
          background-color: $backgroundFade3;
          border: none;
          border-bottom: 1px solid $text;
          color: $text;
          outline: none;
          cursor: not-allowed;
          transition: all 0.3s ease-in-out;
          padding: 1em 1em 0.5em 1em;
          border-radius: .2em .2em 0 0;
          -webkit-filter: drop-shadow(.05em -.3em .05em rgba(79, 82, 97, 0.205));
          filter: drop-shadow(-.02em .2em 0.1em $backgroundFade2);
        }
      }

    }


  }



  .contactImg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 40vw;
    height: auto;
    transform: rotate(90deg);

    img {
      max-width: 100%;
      height: auto;
    }

    @media (max-height: 600px) {
      display: none;
    }

  }

}