.header {

  width: 100%;
  height: 15vh;

  position: fixed;
  z-index: 10;
  transition: all 0.4s ease-in-out;
  background-color: transparent;

  display: grid;
  grid-template-columns: 12% auto auto 12%;
  grid-template-rows: 100%;

  @include media('<=tablet') {
    // TEST FOR MOBILE KEYBOARD DETECTION
    // height: 15vh;
    height: 120px;
    grid-template-columns: 8% auto auto 8%;
    grid-template-rows: 100%;
  }

  &--scrolled {
    height: 8vh;
    box-shadow: 0px 0px 10px $shadow;
    background-color: $highlight;

    @include media('<=tablet') {
      // TEST FOR MOBILE KEYBOARD DETECTION
      // height: 12vh;
      height: 90px;
    }
  }

  .header-namespace {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    // z-index: 20;
    transition: all 0.4s ease-in-out;

    .first-name {
      font-size: 1.1em;
      font-weight: 400;
      padding-right: 4px;
    }

    .last-name {
      font-size: 1.1em;
      font-weight: 900;
    }

    .header-breadcrumb {
      padding-left: 10px;

      @include media('<=tablet') {
        font-size: 0.8em;
      }
    }
  }

  .inner-header {
    grid-column: 3 / 4;
    grid-row: 1 / 2;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    z-index: 20;
    transition: all 0.4s ease-in-out;

    button {

      border: none;
      background: none;
      outline: none;
      cursor: pointer;

      .button-wrapper {
        width: auto;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

  }
}