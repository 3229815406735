.algaeContainer {
  height: 170vh;
  width: 100%;
  overflow: hidden;
  background-color: $background;

  @include media('<=tablet') {
    height: 150vh;
  }

  @include media('<380px') {
    height: 160vh;
  }

  .hero {
    position: relative;
    width: 100%;
    height: 100vh;

    top: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    text-align: center;

    @include media('<=tablet') {
      top: 30vh;
    }

    .textWrapper {
      z-index: 2;

      .title {
        font-size: 5em;
        font-weight: bold;
        line-height: 1.1em;

        @include media('<=tablet') {
          font-size: 3.5em;
          border-radius: 10px;
        }

      }

    }


  }

  .algaeWrapper {
    position: absolute;
    top: 0vh;
    width: 100%;
    filter: grayscale(0.2);
    overflow: hidden;

    .algaeImage {
      position: relative;
      width: 100%;
      height: auto;
      transition: all 0.4s ease-in-out;

      @include media('<=tablet') {
        width: 320%;
        height: auto;
        animation-name: backgroundEffect;
        animation-duration: 200s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }
  }

  .spacer {
    position: relative;
    left: -5px;
    bottom: 1vh;
    width: 104vw;
    z-index: 1;
    transform: scaleY(1);
    -webkit-filter: drop-shadow(0 -2em 2em rgba(59, 71, 54, 0.7));
    filter: drop-shadow(0 -2em 2em rgba(59, 71, 54, 0.7));

    @include media('<=tablet') {
      bottom: 15vh;
    }
  }

  .description {
    z-index: 3;
    position: relative;
    background-color: $background;
    top: -40vh;
    height: 50vh;
    padding: 0 12%;
    display: flex;
    // border: thin rebeccapurple groove;
    // background-color: red;

    @include media('<=tablet') {
      flex-direction: column;
      padding: 0 8%;
      top: -25vh;
    }

    @include media('<380px') {
      height: 60vh;
      flex-direction: column;
      padding: 0 8%;
      top: -25vh;
    }


    .descriptionFirst {
      width: 52vw;
      // border: thin red groove;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @include media('<=tablet') {
        width: 100%;
      }

    }

    .descriptionSecond {
      width: 42vw;
      // border: thin red groove;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 4%;

      @include media('<=tablet') {
        padding: 0;
        width: 100%;
      }

    }

    .constructionWrapper {
      position: absolute;
      bottom: -10vh;
      left: 2em;
      width: 50vh;
      opacity: .1;
      // transform: scaleX(-1);

      @include media('<=tablet') {
        left: 50vw;
        transform: scaleX(-1);
        width: 20vh;
      }
    }
  }



  .footer {
    position: fixed;
    z-index: 5;

    width: 50vw;
    height: 40vh;
    bottom: -20vh;
    border-radius: 0 50em 0 0;

    background: linear-gradient(-57deg, hsla(113, 30%, 39%, 0.054), hsla(115, 25%, 15%, 0.5) 100.71%);

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2vw 0 0 5vw;
    flex-direction: column;

    h4 {
      font-size: 2em;
    }


    @include media('<=tablet') {
      align-items: center;
      bottom: 0vh;
      width: 100vw;
      height: 12vh;
      padding: 2vh 4vh;
      border-radius: 0 0em 0 0;


      h4 {
        font-size: 1em;
      }
    }

  }

}



@keyframes backgroundEffect {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}