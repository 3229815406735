.hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;

  .menu-secondary-background-color {
    background-color: $highlight;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .menu-layer {
    position: relative;
    background:linear-gradient(-37deg, $backgroundFade, $menu 50.71%);
    height: 100%;
    overflow: hidden;

    .menu-city-background {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-size: cover;
      background-repeat: no-repeat;
      animation: cameraPan 30s infinite;
    }

    .wrapper {
      position: relative;
      padding: 0 20px;
      display: grid;

      grid-template-columns: 10% 38% 4% 38% 10%;
      grid-template-rows: 5vh auto auto 10vh;

      @include media('<=tablet') {
        grid-template-columns: auto;
        grid-template-rows: 50% 50%;
      }

      @include media('>LGdesktop') {
        grid-template-columns: 20% 40% auto 20%;
      }

      .menu-projects {
        grid-column: 4 / 5;
        grid-row: 2 / 3;

        @include media('<=tablet') {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          margin-bottom: 20px;
        }


      }

      .menu-common {

        grid-column: 2 / 3;
        grid-row: 2 / 4;

        @include media('<=tablet') {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

      }

      nav {
        display: block;

        ul {
          padding: 0;
          margin: 0;

          h1 {
            color: $backgroundFade2;
            font-size: 4em;
            margin: 0em 0 1em 0;

            @include media('<=tablet') {
              font-size: 3em;
              margin: 1em 0 .5em 0;
            }

            @include media('<380px') {
              font-size: 2.5em;
              margin: 1em 0 .7em 0;
            }


            &:before {
              content: '';
              display: block;
              position: relative;
              width: 150px;
              height: 8px;
              top: 1.25em;
              left: -1.5em;
              background-color: $highlight;

              @include media('<=tablet') {
                width: 90px;
                height: 10px;
                top: 1.25em;
                left: -1em;
              }

              @include media('<380px') {
                width: 90px;
                height: 10px;
                top: 1.3em;
                left: -1em;
              }
  
            }
          }

          li {
            list-style: none;
            font-size: 3rem;
            font-weight: 700;
            width: 30vw;
            
            height: auto;
            // overflow: hidden;
            display: flex;
            flex-direction: column;
            margin: 0 0em 1em 0;


            @include media('<=tablet') {
              width: auto;
              margin: 0 0em .5em 0;
              font-size: 2rem;
            }

            @include media('<380px') {
              margin: 0 0em 0.3em 0;
              font-size: 1.5rem;
            }


            a {
              cursor: pointer;
              color: white;
              text-decoration: none;

              &:hover {
                color: $highlight;
              }

              .imgWrapper {
                position: absolute;
                // display: inline;
                top: 0;
                left: -1em;
                width: 30px;
                height: 30px;
                // overflow: hidden;
  

                @include media('<=tablet') {
                  position: inherit;
                  // display: inline;
                  top: 0em;
                  left: 0em;
                }

              }

            }


            p {
              text-align: justify;
              font-size: .3em;
              color: $backgroundFade3;
              font-weight: normal;
            }


          }
        }
      }


    }

  }
}


