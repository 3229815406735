@import "../../../styles/Utils/Breakpoints";


.dennis-wrapper {
    // border: 1px red solid;
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // bottom: 10px;
    // right: 0px;
    // // 
    overflow: hidden;

    svg {
        // transform: scale(.4) translate(60%, -5%);
        position: absolute;
        width: -50%;
        height: 300%;
        bottom: 90%;
        left: 10%;
        transform: scaleX(-1);

        @include media('<=tablet') {
            position: absolute;
            width: 10;
            height: 220%;
            bottom: 55%;
            left: 74%;
            transform: scaleX(1);
    
        }
    }
}