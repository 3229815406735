@import "./Variables";

.burger {
    width: 20px;
    height: 2px;
    background: $text;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        background: $text;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
    }

    &:before {
        transform: translateY(-7px) translateX(-10px);
    }

    &:after {
        transform: translateY(7px) translateX(-10px);
    }


    &--open {
        transform: translateX(-25px);
        background: transparent;
        width: 20px;
        height: 2px;
        background:transparent;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            background: rgb(255, 255, 255);
            border-radius: 5px;
            transition: all 0.2s ease-in-out;
            transform: rotate(-45deg) translate(10px, 10px);
        }

        &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            background: rgb(255, 255, 255);
            border-radius: 5px;
            transition: all 0.2s ease-in-out;
            transform: rotate(45deg) translate(10px, -10px);
        }
    }
}